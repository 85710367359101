<template>
  <modal @update:show="onShowChange" :show="show">
    <h6 slot="header" class="modal-title">{{ item.id ? this.$t('message.edit') : this.$t('message.new') }}</h6>

    <base-input required label="Text" v-model="item.name" :validator="$v.item.name"></base-input>

    <template slot="footer">
      <base-button class="ml-auto" type="link" @click="onCancelClick">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSaveClick" :disabled="$v.$invalid">{{$t('message.save')}}</base-button>
    </template>
  </modal>
</template>

<script>

import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
  name: "options-form",
  props: {
    item: {
      type: Object
    },
    label: {
      type: String
    },
    show: {
      type: Boolean
    }
  },
  validations: {
    item: {
      name: {required, maxLength: maxLength(100)},
    },
  },
  methods: {
    ...mapActions(['loadProjectOptions']),
    onCancelClick() {
      this.$v.$reset()
      this.onShowChange(false);
    },
    onSaveClick: async function() {
      const { $store, item } = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return
      }

      if (item.id) {
        await $store.dispatch("updateOption", {
          id: item.id,
          data: item
        });
      } else {
        if (item.type == 'AREA') {
          let currentOptions = await this.loadProjectOptions(item.project_id)
          item.name = this.setNumberedAreaValue(item.name, currentOptions.filter(o => o.type == 'AREA').map(o => o.name));
        }

        await $store.dispatch("createOption", { data: item });
      }

      this.$v.$reset()
      this.onShowChange(false);
      this.$emit("changed");
    },
    onShowChange(value) {
      this.$emit("update:show", value);
    },
    setNumberedAreaValue(value, currentOptions) {
        if (currentOptions.length > 0) {
          let existingNumbers = [];
          currentOptions.filter(o => {
            if (!Number.isNaN(parseInt(o.slice(0,1))) && o.includes(' -')) {
              let space = o.indexOf(' ');
              let leadingNumber = o.substring(0, space);
              existingNumbers.push(leadingNumber);
            }
          })

          let maxValue = Math.max(...existingNumbers);
          let nextNumber = maxValue + 1;

          if (maxValue < 9) {
            value = '0' + nextNumber + ' - ' + value;
          } else {
            value = nextNumber + ' - ' + value;
          }
        } else {
          value = '01 - ' + value;
        }

        return value
    }
  }
};
</script>
