<template>
  <div class="card" v-loading="isLoading">
    <div class="card-header">
      <div class="d-flex">
        <h3 class="m-0 pr-4 flex-grow-1">{{$t('message.einsatzorte')}}</h3>
        <el-tooltip :content="$t('message.new')" placement="top">
          <base-button type="primary" icon round size="sm" @click="onCreate" :disabled="project.state === 'ARCHIVED'">
            <i class="fas fa-plus p-0"></i>
          </base-button>
        </el-tooltip>
      </div>
    </div>
    <el-table
      class="table-responsive table-compact"
      header-row-class-name="thead-light"
      row-key="id"
      :data="locations">
      <el-table-column :label="$t('message.einsatzorte')" prop="address.name" sortable>
        <template v-slot="{row}">
          <span style="width: 10px; height: 10px; margin-right: 5px; display: inline-block; border-radius: 50px;" :style="{backgroundColor: row.color}"></span> {{ row.address.name }}
        </template>
      </el-table-column>
      <el-table-column label width="88">
        <template v-slot="{row}">
          <el-tooltip :content="$t('message.edit')" placement="top">
            <base-button type="link" size="sm" @click="onEdit(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-edit text-black"></i>
            </base-button>
          </el-tooltip>
          <el-tooltip :content="$t('message.delete')" placement="top">
            <base-button type="link" size="sm" @click="onDelete(row)" :disabled="project.state === 'ARCHIVED'">
              <i class="fas fa-trash text-black"></i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <einsatzorte-form
      v-if="address"
      :address="address"
      :project="project"
      :show.sync="showForm"
      @changed="onChanged"
    ></einsatzorte-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { omit } from "lodash";

import EinsatzorteForm from "./EinsatzorteForm";

export default {
  name: "Einsatzort",
  components: {
    EinsatzorteForm
  },
  props: {
    project: { type: Object }
  },
  computed: {
    ...mapGetters(["locations"])
  },
  data() {
    return {
      address: null,
      isLoading: true,
      showForm: false
    };
  },
  watch: {
    project() {
      this.isLoading = true;
      this.load();
    }
  },
  methods: {
    onChanged() {
      this.isLoading = true;
      this.showForm = false;
      this.load(this.project.id);
    },
    onCreate() {
      this.address = {
        address: {
          name: "",
          street: "",
          no: "",
          address2: "_",
          zip: "",
          city: "",
          country: "DE",
          type: "EINSATZ",
        }
      };
      this.showForm = true;
    },
    onDelete: async function(row) {
      try {
        await this.$confirm(
          `Wollen Sie den Einsatzort "${row.address.name}" wirklich löschen?`,
          "Löschen",
          {
            confirmButtonText: "Ja",
            cancelButtonText: "Abbrechen",
            type: "warning",
            center: false
          }
        );
      } catch (error) {
        return;
      }

      this.isLoading = true;
      await this.$store.dispatch("deleteProject_address", { id: row.id });
      // TODO JVI 16.11.19 Die Adresse wird NICHT gelöscht. Wir müssen aber noch die Wiederverwendung bauen
      // await this.$store.dispatch("deleteAddress", { id: row.id });
      this.load();
    },
    onEdit(row) {
      this.address = { ...row };
      this.showForm = true;
    },
    load: async function() {
      await this.$store.dispatch("getAddressesByProjectId", this.project.id);
      this.isLoading = false;
    }
  },
  mounted() {
    this.load();
  }
};
</script>
